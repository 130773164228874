{
	"ext": "ext.",
	"country": "Country",
	"AB": "Abkhazia",
	"AC": "Ascension Island",
	"AD": "Andorra",
	"AE": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
	"AF": "Afghanistan (‫افغانستان‬‎)",
	"AG": "Antigua and Barbuda",
	"AI": "Anguilla",
	"AL": "Albania (Shqipëri)",
	"AM": "Armenia (Հայաստան)",
	"AO": "Angola",
	"AQ": "Antarctica",
	"AR": "Argentina",
	"AS": "American Samoa (Amerika Sāmoa)",
	"AT": "Austria (Österreich)",
	"AU": "Australia",
	"AW": "Aruba",
	"AX": "Åland Islands",
	"AZ": "Azerbaijan (Azərbaycan)",
	"BA": "Bosnia and Herzegovina",
	"BB": "Barbados",
	"BD": "Bangladesh (বাংলাদেশ)",
	"BE": "Belgium (België)",
	"BF": "Burkina Faso",
	"BG": "Bulgaria (България)",
	"BH": "Bahrain (‫البحرين‬‎)",
	"BI": "Burundi (Uburundi)",
	"BJ": "Benin (Bénin)",
	"BL": "Saint Barthélemy (Saint-Barthélemy)",
	"BM": "Bermuda",
	"BN": "Brunei (Negara Brunei Darussalam)",
	"BO": "Bolivia",
	"BQ": "Caribbean Netherlands",
	"BR": "Brazil (Brasil)",
	"BS": "Bahamas",
	"BT": "Bhutan (འབྲུག)",
	"BV": "Bouvet Island",
	"BW": "Botswana",
	"BY": "Belarus (Беларусь)",
	"BZ": "Belize",
	"CA": "Canada",
	"CC": "Cocos (Keeling) Islands",
	"CD": "Congo (DRC) (Kongo)",
	"CF": "Central African Republic",
	"CG": "Congo (Republic) (Congo-Brazzaville)",
	"CH": "Switzerland (Schweiz)",
	"CI": "Côte d’Ivoire",
	"CK": "Cook Islands",
	"CL": "Chile",
	"CM": "Cameroon (Cameroun)",
	"CN": "China (中国)",
	"CO": "Colombia",
	"CR": "Costa Rica",
	"CU": "Cuba",
	"CV": "Cape Verde (Kabu Verdi)",
	"CW": "Curaçao",
	"CX": "Christmas Island",
	"CY": "Cyprus (Κύπρος)",
	"CZ": "Czech Republic (Česká republika)",
	"DE": "Germany (Deutschland)",
	"DJ": "Djibouti",
	"DK": "Denmark (Danmark)",
	"DM": "Dominica",
	"DO": "Dominican Republic",
	"DZ": "Algeria (‫الجزائر‬‎)",
	"EC": "Ecuador",
	"EE": "Estonia (Eesti)",
	"EG": "Egypt (‫مصر‬‎)",
	"EH": "Western Sahara (‫الصحراء الغربية‬‎)",
	"ER": "Eritrea",
	"ES": "Spain (España)",
	"ET": "Ethiopia",
	"FI": "Finland (Suomi)",
	"FJ": "Fiji (Matanitu Tugalala o Viti)",
	"FK": "Falkland Islands (Islas Malvinas)",
	"FM": "Micronesia",
	"FO": "Faroe Islands (Føroyar)",
	"FR": "France (République française)",
	"GA": "Gabon (République gabonaise)",
	"GB": "United Kingdom",
	"GD": "Grenada",
	"GE": "Georgia (საქართველო)",
	"GF": "French Guiana (Guyane française)",
	"GG": "Guernsey",
	"GH": "Ghana (Gaana)",
	"GI": "Gibraltar",
	"GL": "Greenland (Kalaallit Nunaat)",
	"GM": "Gambia",
	"GN": "Guinea (Guinée)",
	"GP": "Guadeloupe",
	"GQ": "Equatorial Guinea (Guinea Ecuatorial)",
	"GR": "Greece (Ελλάδα)",
	"GS": "South Georgia and the South Sandwich Islands",
	"GT": "Guatemala",
	"GU": "Guam (Guåhån)",
	"GW": "Guinea-Bissau (Guiné Bissau)",
	"GY": "Guyana",
	"HK": "Hong Kong (香港)",
	"HM": "Heard Island and McDonald Islands",
	"HN": "Honduras",
	"HR": "Croatia (Hrvatska)",
	"HT": "Haiti (République d'Haïti)",
	"HU": "Hungary (Magyarország)",
	"ID": "Indonesia",
	"IE": "Ireland",
	"IL": "Israel (‫ישראל‬‎)",
	"IM": "Isle of Man",
	"IN": "India (भारत)",
	"IO": "British Indian Ocean Territory",
	"IQ": "Iraq (‫العراق‬‎)",
	"IR": "Iran (‫ایران‬‎)",
	"IS": "Iceland (Ísland)",
	"IT": "Italy (Italia)",
	"JE": "Jersey",
	"JM": "Jamaica",
	"JO": "Jordan (‫الأردن‬‎)",
	"JP": "Japan (日本)",
	"KE": "Kenya",
	"KG": "Kyrgyzstan (Кыргызстан)",
	"KH": "Cambodia (កម្ពុជា)",
	"KI": "Kiribati",
	"KM": "Comoros (‫جزر القمر‬‎)",
	"KN": "Saint Kitts and Nevis",
	"KP": "North Korea (조선 민주주의 인민 공화국)",
	"KR": "South Korea (대한민국)",
	"KW": "Kuwait (‫الكويت‬‎)",
	"KY": "Cayman Islands",
	"KZ": "Kazakhstan (Казахстан)",
	"LA": "Laos (ລາວ)",
	"LB": "Lebanon (‫لبنان‬‎)",
	"LC": "Saint Lucia",
	"LI": "Liechtenstein",
	"LK": "Sri Lanka (ශ්‍රී ලංකාව)",
	"LR": "Liberia",
	"LS": "Lesotho",
	"LT": "Lithuania (Lietuva)",
	"LU": "Luxembourg (Lëtzebuerg)",
	"LV": "Latvia (Latvija)",
	"LY": "Libya (‫ليبيا‬‎)",
	"MA": "Morocco (‫المغرب‬‎)",
	"MC": "Monaco",
	"MD": "Moldova (Republica Moldova)",
	"ME": "Montenegro (Crna Gora)",
	"MF": "Saint Martin (Saint-Martin)",
	"MG": "Madagascar (Madagasikara)",
	"MH": "Marshall Islands",
	"MK": "Macedonia (FYROM) (Македонија)",
	"ML": "Mali",
	"MM": "Myanmar (Burma) (မြန်မာ)",
	"MN": "Mongolia (Монгол)",
	"MO": "Macau (澳門)",
	"MP": "Northern Mariana Islands",
	"MQ": "Martinique",
	"MR": "Mauritania (‫موريتانيا‬‎)",
	"MS": "Montserrat",
	"MT": "Malta",
	"MU": "Mauritius (Moris)",
	"MV": "Maldives",
	"MW": "Malawi (Malaŵi)",
	"MX": "Mexico (México)",
	"MY": "Malaysia",
	"MZ": "Mozambique (Moçambique)",
	"NA": "Namibia (Namibië)",
	"NC": "New Caledonia (Nouvelle-Calédonie)",
	"NE": "Niger (Nijar)",
	"NF": "Norfolk Island",
	"NG": "Nigeria",
	"NI": "Nicaragua",
	"NL": "Netherlands (Nederland)",
	"NO": "Norway (Norge)",
	"NP": "Nepal (नेपाल)",
	"NR": "Nauru (Repubrikin Naoero)",
	"NU": "Niue (Niuē)",
	"NZ": "New Zealand",
	"OM": "Oman (‫عُمان‬‎)",
	"OS": "South Ossetia",
	"PA": "Panama (Panamá)",
	"PE": "Peru (Perú)",
	"PF": "French Polynesia (Polynésie française)",
	"PG": "Papua New Guinea",
	"PH": "Philippines (Republika ng Pilipinas)",
	"PK": "Pakistan (‫پاکستان‬‎)",
	"PL": "Poland (Polska)",
	"PM": "Saint Pierre and Miquelon",
	"PN": "Pitcairn",
	"PR": "Puerto Rico",
	"PS": "Palestine (‫فلسطين‬‎)",
	"PT": "Portugal (República Portuguesa)",
	"PW": "Palau (Beluu er a Belau)",
	"PY": "Paraguay (Tetã Paraguái)",
	"QA": "Qatar (‫قطر‬‎)",
	"RE": "Réunion (La Réunion)",
	"RO": "Romania (România)",
	"RS": "Serbia (Србија)",
	"RU": "Russia (Россия)",
	"RW": "Rwanda",
	"SA": "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
	"SB": "Solomon Islands",
	"SC": "Seychelles (Repiblik Sesel)",
	"SD": "Sudan (‫السودان‬‎)",
	"SE": "Sweden (Sverige)",
	"SG": "Singapore (Singapura) (新加坡共和国)",
	"SH": "Saint Helena",
	"SI": "Slovenia (Slovenija)",
	"SJ": "Svalbard and Jan Mayen",
	"SK": "Slovakia (Slovensko)",
	"SL": "Sierra Leone",
	"SM": "San Marino",
	"SN": "Senegal (Sénégal)",
	"SO": "Somalia (Soomaaliya)",
	"SR": "Suriname",
	"SS": "South Sudan (‫جنوب السودان‬‎)",
	"ST": "São Tomé and Príncipe",
	"SV": "El Salvador",
	"SX": "Sint Maarten",
	"SY": "Syria (‫سوريا‬‎)",
	"SZ": "Swaziland (Umbuso weSwatini)",
	"TA": "Tristan da Cunha",
	"TC": "Turks and Caicos Islands",
	"TD": "Chad (Tchad)",
	"TF": "French Southern Territories",
	"TG": "Togo (République togolaise)",
	"TH": "Thailand (ไทย)",
	"TJ": "Tajikistan (Ҷумҳурии Тоҷикистон)",
	"TK": "Tokelau",
	"TL": "Timor-Leste (Timór-Leste)",
	"TM": "Turkmenistan (Türkmenistan)",
	"TN": "Tunisia (‫تونس‬‎)",
	"TO": "Tonga",
	"TR": "Turkey (Türkiye)",
	"TT": "Trinidad and Tobago",
	"TV": "Tuvalu",
	"TW": "Taiwan (台灣)",
	"TZ": "Tanzania",
	"UA": "Ukraine (Україна)",
	"UG": "Uganda",
	"UM": "United States Minor Outlying Islands",
	"US": "United States",
	"UY": "Uruguay",
	"UZ": "Uzbekistan (Oʻzbekiston)",
	"VA": "Vatican City (Città del Vaticano)",
	"VC": "Saint Vincent and the Grenadines",
	"VE": "Venezuela",
	"VG": "British Virgin Islands",
	"VI": "U.S. Virgin Islands",
	"VN": "Vietnam (Việt Nam)",
	"VU": "Vanuatu",
	"WF": "Wallis and Futuna (Wallis-et-Futuna)",
	"WS": "Samoa (Sāmoa)",
	"XK": "Kosovo",
	"YE": "Yemen (‫اليمن‬‎)",
	"YT": "Mayotte",
	"ZA": "South Africa",
	"ZM": "Zambia",
	"ZW": "Zimbabwe",
	"ZZ": "International"
}