import React from 'react';

const ExploreIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#889096" strokeWidth="2"/>
            <path stroke="#889096" strokeWidth="2" d="M2.5 12H22M15 12c0 2.95-.44 5.58-1.11 7.44a6.72 6.72 0 0 1-1.1 2.04c-.37.44-.64.52-.79.52-.15 0-.42-.08-.8-.52a6.72 6.72 0 0 1-1.09-2.04A22.66 22.66 0 0 1 9 12c0-2.95.44-5.58 1.11-7.44.34-.93.72-1.61 1.1-2.04.37-.44.64-.52.79-.52.15 0 .42.08.8.52.37.43.75 1.11 1.09 2.04A22.66 22.66 0 0 1 15 12Z"/>
        </svg>
    );
};

export default ExploreIcon;
